<template>
    <v-dialog
        v-model="dialog"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-card
                v-bind="attrs"
                v-on="on"
                color="#f1aa48" 
                class="white--text"
                height="150"
            >
                <v-card-title>
                    <v-row dense>
                        <v-col cols="2">
                            <v-icon color="white" size="60">mdi-keyboard-outline</v-icon>
                        </v-col>
                        <v-col cols="10">
                           Escreva o seu elogio ou reclamação.
                        </v-col>
                    </v-row>
                  
                </v-card-title>
            </v-card>
        </template>
        <v-card 
        >
            <v-card-title>
                Formulário
                <v-spacer></v-spacer>
                <v-btn 
                    outlined 
                    icon
                    @click="close"
                ><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                
                <v-row dense>
                    <v-col :xs="12" :sm="12" :md="3" :lg="3" :xl="3" cols="12">
                        Usuário: 
                        <v-chip
                            class="ma-2"
                            color="blue"
                            label
                            outlined
                        >
                            {{ usuario.first_name }} {{ usuario.last_name }}
                        </v-chip>
                    </v-col>
                </v-row>
                <v-row>
                  <v-card-subtitle class="subtitle-1">
                    Este serviço é altamente confidencial e somente a coordenação de gestão de pessoas terá acesso às suas informações reportadas.
                  </v-card-subtitle>
                </v-row>
                
                <v-row dense>
                 
                    <v-col>
                        <v-stepper v-model="e1">
                            <v-stepper-header>
                                <template v-for="v in campos">
                                    <v-stepper-step
                                        color="blue"
                                        :key="`${v.ordem}c`"
                                        :complete="e1 > v.ordem"
                                        :step="v.ordem"
                                    >
                                        
                                    </v-stepper-step>
                                    <v-divider
                                    v-if="v.ordem !== campos"
                                    :key="v.ordem"
                                ></v-divider>
                                </template>
                            </v-stepper-header>

                            <v-stepper-items>
                            <v-stepper-content
                                v-for="v in campos"
                                :key="v.ordem+'b'"
                                :step="v.ordem"
                            >
                                <v-card
                                    class="mb-12"
                                    flat
                                >
                                    <v-card-text
                                        v-show="v.show"
                                        class="mt-3" 
                                    >
                                        <v-row>
                                            <v-textarea
                                                v-if="v.tipo.text=='textarea'" 
                                                :label="v.label"
                                                v-model="v.resposta"
                                                outlined
                                            ></v-textarea>
                                        </v-row>
                                        <v-row>
                                            <v-radio-group 
                                                v-if="v.tipo.text=='radio'" 
                                                v-model="v.resposta"
                                                return-object
                                                :label="v.label"
                                            >
                                                <v-radio
                                                    v-for="n in v.data"
                                                    :key="`${n.id}t`"
                                                    :label="n.text"
                                                    :value="n"
                                                ></v-radio>
                                            </v-radio-group>
                                        </v-row>
                                        <v-row class="ma-0 pa-0" dense v-if="v.tipo.text=='checkbox'">
                                            <div class="ma-3 subtitle-1">Para qual área você está fazendo o seu registro?</div>
                                            <v-col
                                                class="ma-0 pa-0"
                                                dense
                                                cols="12"
                                                v-for="c in v.data" :key="c.id+'c'"
                                            >   
                                                <v-checkbox
                                                    class="ma-0 pa-0"
                                                    v-model="v.resposta"
                                                    :label="c.text"
                                                    color="red"
                                                    :value="c.text"
                                                    multiple
                                                    required
                                                    :rules="regra"
                                                ></v-checkbox>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="v.tipo.text=='text-field'">
                                            <v-text-field 
                                                v-model="v.resposta"
                                                :label="v.label"
                                            >
                                            </v-text-field>
                                        </v-row>
                                        <v-row v-if="v.tipo.text=='date'">
                                            <v-col cols="4">
                                                <v-text-field 
                                                v-model="v.resposta"
                                                :label="v.label"
                                                clearable
                                                type="date">
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="v.tipo.text=='time'">
                                            <v-col cols="4">
                                                <v-text-field 
                                                :label="v.label"
                                                clearable
                                                v-model="v.resposta"
                                                type="time">
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="v.tipo.text=='datetime'" justify="center">
                                            <v-col cols="4">
                                                <v-text-field
                                                    :label="v.label" 
                                                    type="date">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-text-field 
                                                    :label="v.label"
                                                type="time">
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-text
                                        v-show="v.tipo.text=='btn'"
                                    >
                                        <v-sheet height="180">
                                            <v-btn 
                                                outlined
                                                color="blue"
                                                block
                                                @click.stop="enviar"
                                                :disabled="show"
                                                class="pa-6 ma-2"
                                                style="background-color:#cae5f3"
                                            >
                                                Enviar para análise&nbsp;&nbsp;&nbsp;
                                                <v-icon right>mdi-send</v-icon>
                                            </v-btn>
                                        </v-sheet>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn
                                            color="secondary"
                                            :disabled="volta"
                                            @click="decrement"
                                            outlined
                                        >
                                        Voltar
                                        </v-btn>
                                        <v-btn
                                            color="secondary"
                                            @click="increment"
                                            :disabled="v.avancar"
                                            outlined
                                        >
                                        Continue 
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>

                            </v-stepper-content>

                            </v-stepper-items>
                        </v-stepper>
                    </v-col>
                    
                </v-row>
            </v-card-text>
        </v-card>
        <v-snackbar
            v-model="loading"
            :color="cor"
        >
            {{ mensagem }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    color="gren"
                    text
                    v-bind="attrs"
                    @click="loadinFalse"
                >
                Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-dialog>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
    name:'Add',
    data:()=>({
        snackbar: false,
        dialog:false,
        conf:{usuario:{data:{}}},
        regra:[e=>!!e||'Precisa esta marcada a opção'],
        
    }),
    computed:{
        ...mapState(['e1','loading']),
        ...mapGetters(['resposta_sugestao','mensagem','cor','campos', 'usuario']),
          /*avanca(){
            // se e1 for igual ao numero de objetos no array, ele desabilita o btn avancar
          let estado = true
            for (let i=0; i.length<=this.campos.length;i++){
              estado
            }
            return estado
            return this.e1==this.campos.length?true:false
        },*/
        volta(){
            // se e1 for maior que valor 1 habilita o btn voltar
            return this.e1>1?false:true
        },
        show(){
            // se e1 for igual ao numero de objetos no array, ele habiliata o btn enviar para análise
            return this.e1==this.campos.length?false:true
        },
    },
    methods:{
        ...mapActions(['increment','decrement']),
        loadinFalse(){
            this.$store.dispatch('btnLoading',false)
        },
        enviar(){
            let array = this.campos
            let key = 'field'
            const convertArrayToObject = (array, key) => {
                const initialValue = {};
                return array.reduce((obj, item) => {
                    return {
                    ...obj,
                    [item[key]]: item.resposta,
                    };
                }, initialValue);
            };

            let arr = convertArrayToObject(array,key)
            
            var config = {
                method: 'post',
                url: 'informe/ouvidorias/',
                data : arr,
                instancia:'ouvidoria'
            };
            
            this.$store.dispatch('btnLoading',true)
            this.$store.dispatch('enviarSugestao',config)
            this.$store.dispatch('reset')
            this.campos.map(e=>e.resposta = '')              
            this.close()
            
        },
        close(){
            this.dialog = false
        }
    },
    mounted(){
        this.conf = JSON.parse(localStorage.getItem('cfgu'))
        this.$store.dispatch('btnLoading',false)
        this.$store.dispatch('sugestoes',{
            method:'get',
            url:'informe/campos/?app=OUVIDORIA',
            instancia:'campos'
        })
        this.$store.dispatch('usuario',{
            method:'get',
            url:`contas/app-usuario/?id=${this.conf.userId}/`,
        })
    },
    watch:{
      campos:{
        handler(val){
          let p = 0
          for (let i=0;i<val.length;i++){
            
            if (val[i].resposta!=undefined){
              val[i].avancar = false
              if(val[i].resposta.id == 4){
                p = val[i].resposta.id
              }
            }
            if (p == 4 && (val[i].tipo.id == 4||val[i].tipo.id == 5)){
              val[i].avancar = false
            }
          }
        }, deep:true
      }
    }
} 
</script>